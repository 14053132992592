import React from "react";
import "./TeamPage.css";
import Layout from "./../components/Layout";
import { Container } from "reactstrap";
import ScottHarrsion from "../assets/ScottHarrison.jpg";
import AmirHarrison from "../assets/AmirHarrison.jpg";
import OmidHarrison from "../assets/OmidHarrison.jpg";

export function TeamPage() {
    return (
        <Layout>
            <Container className="team-page-container">
                <div>Team</div>
                <Container className="scott-harrison team-bio">
                    <img
                        className='Scott'
                        src={ScottHarrsion}
                        alt='Scott Harrison'
                    />
                    <div>Scott Harrison</div>
                    <div>Lead Engineer | Backend Engineer</div>
                    <div>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Ullam quisquam, alias, aperiam repellat beatae ipsum at
                        ad voluptas quo possimus impedit pariatur consectetur
                        voluptatem officiis ab, doloremque soluta similique.
                        Debitis.
                    </div>
                </Container>
                <Container className="amir-harrison team-bio">
                    <img
                        className='Scott'
                        src={AmirHarrison}
                        alt='Amir Harrison'
                    />
                    <div>Amir Harrison</div>
                    <div>Frontend Developer</div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Dolorem eius natus qui facilis illo quam corporis.
                        Accusamus, voluptatum. Tempore unde delectus impedit
                        deleniti, ex optio autem culpa aliquid labore velit.
                    </div>
                </Container>
                <Container className="omid-harrison team-bio">
                    <img
                        className='Scott'
                        src={OmidHarrison}
                        alt='Omid Harrison'
                    />
                    <div>Omid Harrison</div>
                    <div>QA Tester</div>
                    <div>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Consequatur explicabo reiciendis ea harum molestiae
                        quisquam fugit error ex ducimus. Ipsam cumque ipsum
                        corrupti architecto saepe quam quis, reiciendis quia
                        incidunt.
                    </div>
                </Container>
            </Container>
        </Layout>
    );
}
