import React from "react";

export function Services() {
    return (
        <section className='section-features' id='services'>
            <div className='row'>
                <h2>Business Consulting and Software Development</h2>
                <p className='long-copy'>
                    EdwardHarrison, Inc., brings excellence in business class
                    software to small and medium sized businesses.
                </p>
            </div>
            <div className='row'>
                <div className='col span-1-of-4 box'>
                    <i className='icon ion-md-checkmark icon-big'></i>
                    <h3>Software Development</h3>
                    <p>
                        40+ years of software development experience, and
                        knowledge of the industry's most powerful cutting edge
                        technologies.
                    </p>
                </div>
                <div className='col span-1-of-4 box'>
                    <i className='icon ion-md-cash icon-big'></i>
                    <h3>Business Consulting</h3>
                    <p>
                        Edward Harrison, Inc., has a strong background in
                        building business class applications using highly
                        skilled software developers and solid technical methods
                        to suit your company's specific needs.
                    </p>
                </div>
                <div className='col span-1-of-4 box'>
                    <i className='icon ion-md-analytics icon-big'></i>
                    <h3>FMX Technology</h3>
                    <p>
                        FMX is a new, innovative technology invented by our
                        company. The business data model and work flows have
                        always been a complex problem. It's ever evolving and
                        we've managed to lock down part of it, and we still use
                        paper forms to solve the rest of your problems.
                    </p>
                </div>
                <div className='col span-1-of-4 box'>
                    <i className='icon ion-md-heart icon-big'></i>
                    <h3>Customer Care</h3>
                    <p>
                        We'll be with you every step of the way in developing
                        the application you require for your company's
                        profitability. From design, to build, to deployment.
                    </p>
                </div>
            </div>
        </section>
    );
}
