import { useState } from "react";
import { send } from "emailjs-com";
import validator from "validator";

export function ContactEmail() {
    const [toSend, setToSend] = useState({
        id_number: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        find_us: "",
        // newsletter: "",
        message: "",
        isDisabled: false,
    });
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");

    // const first_name = toSend.first_name;
    const phone = toSend.phone;
    const email = toSend.email;

    const validateEmail = (e) => {
        if (validator.isEmail(email)) {
            setEmailError("Valid Email");
        } else {
            setEmailError("Enter valid Email!");
        }
    };

    const validatePhone = (e) => {
        if (validator.isMobilePhone(phone)) {
            setPhoneError("Valid Phone Number");
        } else {
            setEmailError("Enter valid Phone Number");
        }
    };

    // const validate = () => {
    //     return (
    //         first_name.length & phone.length || first_name.length & email.length
    //     );
    // };

    const onSubmit = (e) => {
        e.preventDefault();
        send(
            process.env.REACT_APP_USER_KEY,
            process.env.REACT_APP_TEMPLATE_KEY,
            toSend,
            process.env.REACT_APP_EMAIL_KEY
        )
            .then((response) => {
                console.log("SUCCESS!", response.status, response.text);
            })
            .catch((err) => {
                console.log("FAILED...", err);
            });
    };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
        console.log(e)
        console.log(e.target.value)
    };

    let optionsArray = [
        "",
        "Friends",
        "Search Engine",
        "Advertisement",
        "Other",
    ];
    let options = optionsArray.map((item) => (
        <option key={item} value={item}>
            {item}
        </option>
    ));

    return (
        <section className='section-form js--section-form' id='contact'>
            <div className='row'>
                <h2>Tell Us About Your Project</h2>
            </div>
            <form onSubmit={onSubmit} className='contact-form'>
                <div className='row'>
                    <div className='col span-1-of-3'>
                        <label htmlFor='name'>Name *</label>
                    </div>
                    <div className='col span-2-of-3'>
                        <input
                            type='text'
                            name='first_name'
                            id='first_name'
                            placeholder='First Name'
                            value={toSend.first_name}
                            onChange={handleChange}
                            // autocomplete='new-password'
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col span-1-of-3'>
                        <label htmlFor='name'> </label>
                    </div>
                    <div className='col span-2-of-3'>
                        <input
                            type='text'
                            name='last_name'
                            id='last_name'
                            placeholder='Last Name'
                            value={toSend.last_name}
                            onChange={handleChange}
                            // autocomplete='new-password'
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col span-1-of-3'>
                        <label htmlFor='email'>Email *</label>
                    </div>
                    <div className='col span-2-of-3'>
                        <input
                            type='text'
                            name='email'
                            id='email'
                            placeholder='Your Email'
                            value={toSend.email}
                            onChange={handleChange}
                            onBlur={validateEmail}
                            // autocomplete='new-password'
                        />
                        <div style={{ fontSize: 12, color: "red" }}>
                            {toSend.emailError}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='inputErrorMessage'>{emailError}</div>
                </div>
                <div className='row'>
                    <div className='col span-1-of-3'>
                        <label htmlFor='phone'>Phone Number *</label>
                    </div>
                    <div className='col span-2-of-3'>
                        <input
                            type='text'
                            name='phone'
                            id='phone'
                            placeholder='Your Phone Number'
                            value={toSend.phone}
                            onChange={handleChange}
                            onBlur={validatePhone}
                            // autocomplete='new-password'
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='inputErrorMessage'>{phoneError}</div>
                </div>
                <div className='row'>
                    <div className='col span-1-of-3'>
                        <label htmlFor='find_us'>How did you find us?</label>
                    </div>
                    <div className='col span-2-of-3'>
                        <select
                            name='find_us'
                            id='find_us'
                            value={toSend.find_us}
                            onChange={handleChange}
                        >
                            {options}
                        </select>
                    </div>
                </div>
                {/* <div className='row'>
                    <div className='col span-1-of-3'>
                        <label htmlFor='newsletter'>Newsletter?</label>
                    </div>
                    <div className='col span-2-of-3'>
                        <input
                            type='checkbox'
                            name='newsletter'
                            id='newsletter'
                            checked={toSend.newsletter}
                            onChange={handleChange}
                        />
                    </div>
                </div> */}
                <div className='row'>
                    <div className='col span-1-of-3'>
                        <label htmlFor='message'>Drop us a line</label>
                    </div>
                    <div className='col span-2-of-3'>
                        <textarea
                            name='message'
                            id='message'
                            placeholder='Your Message'
                            value={toSend.message}
                            onChange={handleChange}
                            // required
                        />
                        <div style={{ fontSize: 12, color: "red" }}>
                            {/* {messageError} */}
                        </div>
                    </div>
                </div>
                <button
                    className='btn btn-primary'
                    id='sendit'
                    disabled={false}
                >
                    Send It!
                </button>
                <div style={{ fontSize: 20, color: "green" }}>
                    {/* {submitMessage} */}
                </div>
            </form>
        </section>
    );
}
