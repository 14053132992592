import React from "react";
import { ContactEmail } from "./ContactEmail";
import { Footer } from "../components/Footer";
import "./MainStyle.css";
import "./Home.css";
import ScottHarrsion from "../assets/ScottHarrison.jpg";
import Logo from "../assets/EdwardHarrisonLogo.png";
import { Services } from "./Services";
import Hero from "./Hero";
import Layout from './../components/Layout';
// import { ScrollerMenu } from './../components/ScrollerMenu';

export default function Home() {
    return (
        <Layout>
            
            <div>
                {/* <ScrollerMenu/> */}
                <Hero />
                <Services />
                <ContactEmail />

                <section className='section-author' id='author'>
                    <h2>Software Developer</h2>
                    <p>
                        After 30 years of software development experience, Scott
                        Harrison has designed and programmed automated systems
                        for industries such as Telecommunications, Field
                        Workforce Management, Medical, Dental, Package Delivery
                        and Logistics, Metal Building Construction, and Human
                        Language Translation and Localization. He graduated from
                        the University of Louisiana with a Bachelor of Science
                        in Computer Science and he is continually improving his
                        knowledge of computer science by pursuing certifications
                        on the Microsoft platform. He maintains a high level of
                        expertise for programming Desktop, Web and Back office
                        applications for those industries that need complex
                        order entry procedures and customer relations tools.{" "}
                    </p>
                    <div className='author'>
                        <img
                            className='Scott'
                            src={ScottHarrsion}
                            alt='Scott Harrison'
                        />
                        <p className='author-text'>Scott Edward Harrison</p>
                        <img className='Logo' src={Logo} alt='Logo' />
                    </div>
                </section>
                <div className='sectionfooter'>
                    <Footer />
                </div>
            </div>
        </Layout>
    );
}
