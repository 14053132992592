import React from "react";
import { Col, Container, Row } from "reactstrap";
import { ContactEmail } from './ContactEmail';

export function ContactPage() {
    return (
        <Container className='contact-page-container' fluid>
            <ContactEmail style={{background: 'none'}}/>
            <Row className='justify-content-md-center'>
                <Col>Or Give Us A Call!</Col>
            </Row>
            <Row className='justify-content-md-center'>
                <Col>(719) 284-1830</Col>
            </Row>
        </Container>
    );
}
