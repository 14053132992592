import React from "react";
import { Route, Routes } from "react-router";
import Home from "./pages/Home";
// import Services from "./pages/Services";

// import Layout from "./components/Layout";
import { ContactPage } from "./pages/ContactPage";
import { ServicesPage } from "./pages/ServicesPage";
import { TeamPage } from "./pages/TeamPage";
import { NavMenu } from "./components/NavMenu";


function App() {
    return (
        // <Layout>
        <div>
            <NavMenu />
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/contact' element={<ContactPage />} />
                <Route path='/services' element={<ServicesPage />} />
                <Route path='/team' element={<TeamPage />} />
            </Routes>
        </div>
        // </Layout>
    );
}

export default App;
