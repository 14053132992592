import React, { useState } from "react";
import {
    Collapse,
    Container,
    Navbar,
    NavItem,
    NavLink,
    NavbarToggler,
} from "reactstrap";
import { Link as ReactRouterLink } from "react-router-dom";
import "./NavMenu.css";
import Logo from "../assets/EdwardHarrisonLogo2.png";

export function NavMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 900) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
        // console.log(window.scrollY);
    };

    window.addEventListener("scroll", changeBackground);

    return (
        // <header>
        <Navbar
            className={
                navbar
                    ? "navbar active navbar-expand-sm"
                    : "navbar navbar-expand-sm"
            }
            fixed='top'
        >
            <Container>
                <NavbarToggler
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    className='mr-2'
                />
                <Collapse
                    className='d-sm-inline-flex justify-content-end'
                    isOpen={isOpen}
                    navbar
                ></Collapse>
                <ul className='navbar-nav flex-row justify-content-end '>
                    <NavItem>
                        <NavLink
                            tag={ReactRouterLink}
                            className={
                                navbar
                                    ? "text-white active-logo"
                                    : "text-white inactive-logo"
                            }
                            to='/'
                        >
                            <div>
                                <div className='logo-text'>
                                    <div className='edward'>Edward</div>
                                    <div className='harrison-inc'>
                                        Harrison, Inc
                                    </div>
                                </div>
                                <img className='logo' src={Logo} alt='Logo' />
                            </div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            tag={ReactRouterLink}
                            className="{({ isActive }) => (isActive ? 'active' : 'inactive')} text-white"
                            to='/contact'
                        >
                            CONTACT
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            tag={ReactRouterLink}
                            className="{({ isActive }) => (isActive ? 'active' : 'inactive')} text-white"
                            to='/ServicesPage'
                        >
                            SERVICES
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            tag={ReactRouterLink}
                            className="{({ isActive }) => (isActive ? 'active' : 'inactive')} text-white"
                            to='/team'
                        >
                            TEAM
                        </NavLink>
                    </NavItem>
                </ul>
            </Container>
        </Navbar>
        // </header>
    );
}
