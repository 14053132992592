import React from "react";
import { Link } from "react-router-dom";

export default function Hero() {
    return (
        <div>
            <header></header>
        <div className='hero-text-box'>
            <h1>SOFTWARE DESIGN FOR A NEW GENERATION</h1>

            <Link to='/fmxapps' className='btn btn-full js-scroll-to-FMX'>
                FMX apps
            </Link>
            <Link to='/contact' className='btn btn-ghost js-scroll-to-contact'>
                Tell Us About Your Project
            </Link>
        </div>
        </div>
    );
}
